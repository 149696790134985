'use strict'
import $ from "jquery";
import Hammer from 'hammerjs'
const feather = require('feather-icons');

//set elements
const navWrapper = $('#nav-wrapper');
const navContainer = navWrapper.find('.nav-menu-container');
const mainUl = navContainer.children('ul').first(0);

const iconLeft = '<a href="#" class="nav-icon-left"><i data-feather="chevron-left" width="16" height="16"></i></a>';
const iconRight = '<a href="#" class="nav-icon-right"><i data-feather="chevron-right" width="16" height="16"></i></a>';

let initGetUl = 0;

let defGenNav = $.Deferred();

export let mlNav = () => {
    if(navWrapper.length > 0){

        const hammerElement = navWrapper[0];
        const hammertime = new Hammer(hammerElement);

        initNav(mainUl);

        $.when(defGenNav).done(function () {
            feather.replace();
            setNavSizes();
            getUl(initGetUl)
        });
        
        $(window).on('resize', function(){
            getUl(mainUl);
            setNavMobile(0);
            setNavSizes();
        })

        navContainer.find('li > a').each(function(){
            $(this).on('click', function(e){
                if($(this).hasClass('nav-icon-left')){
                    e.preventDefault();
                    getUl($(this).parents('ul').eq(1)); //get second parent ul, first is containing ul
                }
                else{
                    if($(this).hasClass('nav-link')){
                        e.preventDefault();
                    }
                    
                    const thisLi = $(this).closest('li');
                    const thisUl = thisLi.closest('ul');
                    const childUl = thisLi.children('ul').first();

                    makeLiUnprominent(thisUl)

                    if(childUl.length > 0){
                        getUl(childUl);
                        makeLiProminent($(this).closest('li'))
                    }
                    else{
                        getUl(thisUl);
                    }
                }
            })
        })

        hammertime.on('swiperight', function(e) {
            navGoBack(1);
        });
    } 
}

//SET

let getLevelId = (ul) => {
    return parseInt(ul.attr('data-level'));
}

let setUlLevel = (ul) => {
    let level = 0;
    if(ul.parents('ul').length > 0){
        level = getLevelId(ul.parents('ul').first()) + 1;
    }
    ul.attr("data-level", level);
}

let setNavSizes = () => {
    let navContainerMinHeight = 0;
    navContainer.find('ul').each(function(){
        const ul = $(this);
        let heigth = ul.outerHeight();
        const ulBeforeHeight = parseFloat(window.getComputedStyle(ul[0], ':before').height, 10);
        if(ulBeforeHeight > heigth){
            heigth = ulBeforeHeight;
        }
        ul.attr('data-heigth', heigth)
        if(heigth > navContainerMinHeight){
            navContainerMinHeight = heigth;
            navContainer.css({
                'min-height' : navContainerMinHeight + 'px'
            })
        }
    })
    if(getNavWidth() > navContainer.width()){
        setNavMobile(1);
    }
    else{
        setNavMobile(0);
    }
}


let maxWidth = 0;
let getNavWidth = () => {
    navContainer.find('ul').each(function(){
        let width = $(this).outerWidth();
        let parentUl = $(this).parents('ul').first(); 
        while(parentUl.length != 0){
            width += parentUl.outerWidth();
            parentUl = parentUl.parents('ul').first(); 
        }
        if(width > maxWidth){
            maxWidth = width;
        }
    })
    return maxWidth;
}

let initNav = (ul) => {
    setUlLevel(ul);
    ul.children('li').each(function(){
        const thisLi = $(this);
        const childNav = thisLi.children('ul').first();
        if(childNav.hasClass('highlighted')){
            initGetUl = childNav;
        }
        if(childNav.length > 0){
            thisLi.append(iconRight);
            thisLi.children('a').addClass('nav-link')
            const title = thisLi.find('a').html();
            const url  = thisLi.find('a').attr('href');
            childNav.prepend('<li class="ul-title">'+iconLeft+'<a href="'+url+'">'+title+'</a></li>').addClass('hidden');
            initNav(childNav);
        }
        else{
            return;
        }
    })

    //finalize ul and check if all uls processed
    ul.attr('data-initialized', true);
    if(navContainer.find('ul[data-initialized!=true]').length == 0){
        defGenNav.resolve("Nav Generated");
    }
}


let makeLiProminent = (li) => {
    const ul = li.parents('ul').first();
    ul.children('li').each(function(){
        if($(this)[0] != li[0]){
            $(this).addClass('faded')
        }
    })
}

let makeLiUnprominent = (ul) => {
    ul.children('li').each(function(){
        $(this).removeClass('faded')
    })
}

//CONTROL
//makes provided ul active
let getUl = (ul) => {
    if(ul == 0){
        if($(navContainer).find('.highlighted').length > 0){
            ul = $(navContainer).find('.highlighted');
        }
        else{
            ul = mainUl;
        }
    }
    
    const hiddenUls = ul.parents('ul.hidden').add(ul);

    for(const hiddenUl of hiddenUls){
        const thisUl = $(hiddenUl);
        
        makeLiUnprominent(thisUl)

        navContainer.find('ul').removeClass('active');  //Remove active ul
        hideChildrenUls(thisUl);     //Hide children uls
        thisUl.removeClass('hidden');           //Show ul
        if(getLevelId(thisUl) > 0){             //Make active if not first level
            thisUl.addClass('active');
        }

        setMenuPosition(thisUl);                 //Set position
    }
}

//go back for provided levels
let navGoBack = (levels) => {
    const activeUl = $('ul.active');

    if(activeUl.length > 0){
        let targetUl = activeUl;

        for(let index = 0; index < levels; index++){
            targetUl = targetUl.parents('ul').first();
            makeLiUnprominent(targetUl)
        }

        getUl(targetUl)
    }
    else{
        return;
    }
}

//hide uls beyond level
let hideChildrenUls = (ul) => {
    let level = 0;
    const parentUl = ul.parents('ul').first();
    if(parentUl.length > 0){
        level = getLevelId(parentUl);
    }
    navContainer.find('ul').each(function(){
        if(getLevelId($(this)) > level){
            $(this).addClass('hidden');
        }
    })
}

//helper for setMenuPosition
let getMenuesWidth = (activeMenu) => {
    let menuesWidth = activeMenu.outerWidth();
    activeMenu.parents('ul').each(function(){
        menuesWidth += $(this).outerWidth();
    });

    return menuesWidth;
}

//sets menu position
let setMenuPosition = (activeMenu) => {
    const containerWidth = navContainer.outerWidth();
    const menuesWidth = getMenuesWidth(activeMenu);
    let topUl = activeMenu;
    if(activeMenu.parents('ul').last().length > 0){
        topUl = activeMenu.parents('ul').last();
    }

    if(menuesWidth > containerWidth){
        const leftOffset = containerWidth - menuesWidth;
        topUl.css({
            'left' : leftOffset + 'px'
        })
    }
    else{
        topUl.css({
            'left' : 0
        })
    }
}

let setNavMobile = (mobile) => {
    if(mobile != 0){
        navContainer.addClass('mobile');
    }
    else if(mobile == 0){
        navContainer.removeClass('mobile');
    }
    else{
        return;
    }
}